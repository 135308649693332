/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        size: "md"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "mb-4", attrs: { "align-h": "between" } },
            [
              _c("b-col", { attrs: { cols: "10" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/icon_logo_violet.svg"),
                    alt: ""
                  }
                })
              ]),
              _c("b-col", { staticClass: "text-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "tw-text-eclipse",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.showDialog = false
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      attrs: { icon: "x-circle", "font-scale": "1.5" }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "b-alert",
            {
              attrs: { variant: "warning" },
              model: {
                value: _vm.alert.show,
                callback: function($$v) {
                  _vm.$set(_vm.alert, "show", $$v)
                },
                expression: "alert.show"
              }
            },
            [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "mb-3", attrs: { cols: "12" } },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "heading",
                              staticStyle: { "font-size": "1.5rem" }
                            },
                            [_vm._v(" Cancel Pending Order ")]
                          ),
                          _c("p", [
                            _vm._v(
                              "Confirm to cancel and refund the pending order."
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " The refill count of the prescription will be returned to its previous value. "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " Note, when cancelling Progesterone, any pending Estrogen orders will be cancelled as well. "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-row",
                            {
                              staticClass: "mt-4",
                              attrs: { "align-h": "end" }
                            },
                            [
                              _c(
                                "b-col",
                                { attrs: { offset: "1" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        block: "",
                                        variant: "outline-dark",
                                        pill: "",
                                        disabled: _vm.processing
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.showDialog = false
                                        }
                                      }
                                    },
                                    [_vm._v(" Nevermind ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        block: "",
                                        variant: "secondary",
                                        pill: "",
                                        disabled: _vm.processing
                                      },
                                      on: { click: _vm.onCancelPendingOrder }
                                    },
                                    [_vm._v(" Cancel Order ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }